<template>
  <VModalWidget :submit="submit" :dismiss="dismiss" :submitting="submitting" :submit-title="$t('labels.save')">
    <template v-slot:title>{{ $t('labels.contractNumber') }}</template>
    <template v-slot:content>
      <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
      <v-form> <CommissionField v-model.trim="form.meta.commissioning" /> </v-form>
    </template>
  </VModalWidget>
</template>

<script>
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { VModalWidget, BaseForm, FormErrors } from '@argon/app/components'
import { NAMESPACE } from '@argon/evo/entities/store'
import DealInfoModalMixin from '../mixins/DealInfoModalMixin'
import { CommissionField } from '@/components'

export default {
  name: 'CommissioningModal',
  extends: BaseForm,
  mixins: [DealInfoModalMixin],
  components: {
    VModalWidget,
    FormErrors,
    CommissionField
  },
  validations: {
    form: {
      meta: {
        commissioning: { required }
      }
    }
  },
  data: () => ({
    form: {
      meta: {
        commissioning: null
      }
    }
  }),
  computed: {
    commissioning() {
      return this.boardItem ? this.boardItem.entity.meta.commissioning : null
    },
    numberErrors() {
      const errors = []
      if (!this.$v.form.meta.commissioning.$dirty) return errors
      !this.$v.form.meta.commissioning.required && errors.push(this.$t('required'))
      this.getServerErrors('commissioning', errors)
      return errors
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['patchEntity']),
    submit() {
      if (this.$v.form.$invalid) {
        return this.$v.form.$touch()
      }
      this.submitting = true
      let payload = {
        proxy: this.entityURL,
        url: this.baseURL,
        data: this.form
      }
      this.patchEntity(payload)
        .then((resp) => {
          this.dismiss()
        })
        .catch(this.setServerErrors)
        .finally(() => {
          this.submitting = false
        })
    }
  },
  created() {
    this.form.meta.commissioning = this.boardItem.entity.meta.commissioning
  }
}
</script>
